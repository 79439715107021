<template>
    <div class="kub-usadba-theme">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: "AuthLayout",
    components: {
    },
    created: function () {
    }
}
</script>

<style scoped>

</style>
