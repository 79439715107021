import Vue from 'vue';
import App from '@/apps/auth/App.vue';
import store from '@/apps/auth/store';
import router from '@/apps/auth/router'

require('@/global-components');

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
