import Vue from 'vue'
import Router from 'vue-router'

const AppLayout = () => import('@/apps/auth/views/AppLayout')
const OAuth = () => import('@/apps/auth/views/pages/OAuth.vue')
const Login = () => import('@/apps/auth/views/pages/Login.vue')
const Register = () => import('@/apps/auth/views/pages/Register.vue')

Vue.use(Router)

export default new Router({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/login',
            redirect: {name: 'OAuth'},
            name: 'AppLayout',
            component: AppLayout,
            children: [
                {
                    path: 'oauth',
                    name: 'OAuth',
                    component: OAuth,
                },
                {
                    path: 'phone',
                    name: 'PhoneLogin',
                    component: Login,
                },
                {
                    path: 'email',
                    name: 'EmailLogin',
                    component: Login,
                },
            ]
        },
    ]
})
